@media screen and (max-width: 768px) {
.react-responsive-modal-modal {
    padding: 16px;
    }
    .rdrDateRangePickerWrapper {
        display: block !important;
    }

    .rdrDefinedRangesWrapper {
        border: none;
        width: 100%;
    }

    .rdrDefinedRangesWrapper .rdrStaticRanges {
        max-width: 100%;
        width: 332px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;
    }

    .rdrDefinedRangesWrapper .rdrStaticRanges .rdrStaticRange {
        width: 33.33%;
    }

    .rdrDefinedRangesWrapper .rdrStaticRanges .rdrStaticRange>.rdrStaticRangeLabel {
        padding-left: 16px;
        padding-right: 16px;
    }

    .rdrDefinedRangesWrapper .rdrInputRanges {
        display: none;
    }

    .rdrCalendarWrapper,
    .rdrDateRangeWrapper,
    .rdrMonth {
        width: 100%;
    }

    .rdrMonth {
        padding-left: 0;
        padding-right: 0;
    }

    .rdrCalendarWrapper .rdrMonthAndYearWrapper .rdrNextPrevButton {
        margin: 0;
    }
}