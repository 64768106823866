body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F4ED;
}

html {
  background: #F9F4ED;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Progress Bar */
progress[value] {
  border-radius: 9999px;
}

progress[value]::-webkit-progress-bar {
  border-radius: 9999px;
}

progress[value]::-webkit-progress-value {
  border-radius: 9999px;
}

progress[value]::-moz-progress-bar {
  border-radius: 9999px;
}

/* Custom Modal */
.react-responsive-modal-modal {
  width: 100%;
}

.react-responsive-modal-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.analytics-table>table {
  width: 100%;
}

.analytics-table>table>thead tr th {
  text-align: center;
  justify-content: center;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #ECE1D7;
}

.analytics-table>table tbody tr td,
.analytics-table>table tr th {
  padding: 12px;
  border: 1px solid #ECE1D7;
}

.analytics-table>table>thead tr th .sort-icon {
  opacity: 0;
}

.analytics-table>table>thead tr th:hover .sort-icon {
  opacity: 1;
}

.fixed-col>table>thead>tr>th:first-child,
.fixed-col>table>tbody>tr>td:first-child {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
}

/* Navbar */
.navbar {
  /* height: 90px; */
  /* gap: 20px; */
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ECE1D7;
}

/* Login Page */

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Cohort Graph */

.cohort-graph p {
  font-weight: 400;
  font-size: 10px !important;
  color: #99968b;
}
