@font-face {
    font-family: "Maison";
    src: url('../font/Maison-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: "Maison";
    src: url('../font/Maison-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: "Maison";
    src: url('../font/Maison-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: "Maison";
    src: url('../font/Maison-Demi.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: "Maison";
    src: url('../font/Maison-Bold.otf') format('opentype');
    font-weight: 700;
}

.font-family-maison {
    font-family: "Maison";
}
